<template>
    <div class="header">
        <div class="inner-header flex">
            <img src="../../assets/zain_gray.png">
        </div>
        <div>
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </div>
    </div>
    <div class="contact-form-area pd-top-100 pd-bottom-100" style="padding-top: 30px;padding-bottom: 40px;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 mt-4 mt-lg-0 ps-lg-5">
                    <div class="contact-form-inner-wrap">
                        <div class="section-title mb-0">
                            <h3 class="small-title mt-0" style="font-size: 20px;letter-spacing: 0;">اشتراك جديد</h3>
                        </div>
                        <form class="mt-5 mt-md-4" @submit.prevent="Subscrib()">
                            <p class="sub-title mb-3 text-center zoal arabickufi" style="font-size:  18px; width:100%; font-weight: bold;">اشترك الآن واستمتع بتجربة فريدة من منصة نادي بــــــز يحتوي علي بوابة الترفيه الرقمي بنمط الحياة العصرية</p>
                            <div class="row">                          
                                <div class="col-12">
                                    <a >
                                    <button class="btn btn-base button pointer header-btn" style="width: 100%; border-radius: 5px;">
                                        <span v-if="loading" class="px-1 arabicKufi">اشتـــراك</span> &nbsp;
                                        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                        <span v-else class="arabickufi">اشتراك الان !</span>
                                    </button>
                                    </a>
                                </div>
                                <router-link style="font-size:  18px; width:100%; font-weight: bold; inherit;color: #5d9cec;" to="/Login" class="btn btn-link">او اضغط هنا لتسجيل الدخول</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- contact form area end -->
</template>

<script>
export default {
    name: 'Subscribes',
    data() {
        return {
          loading: false,
        };
    },
    mounted() {
        if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
        }
    },
    methods: {
        Subscrib() {
            window.open("https://dsplp.sd.zain.com/?p=2887461945", "_self")
            // window.open("http://test.zaindsp.com:3033/?p=3136914724", "_blank");
        }
    }
}
</script>

<style>
            h3 {
                font-weight:600;
                letter-spacing: 2px;
                font-size:32px;
            }
            p {
                letter-spacing: 0.1px;
                font-size:14px;
                color: #333333;
            }

            .header {
                position:relative;
                text-align:center;
                /* background: linear-gradient(60deg, rgb(18, 5, 68) 0%, rgba(20, 0, 255, 0.48) 100%); */
                background-image: linear-gradient(0deg, #481582 0, #70499D) !important;
                color:white;
            }
            .inner-header img{
                width:15%;
                position: relative;
                margin-top: 6%;

            }


            .inner-header {
                height:20vh;
                width:100%;
                margin: 0;
                padding: 0;
            }

            .flex { /*Flexbox for containers*/
                display: flex;
                flex-direction:column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .bottom-container{
                display:flex;
                align-items:center;
            }

            a{
                text-decoration:none;
                margin: 0px 5px;
                letter-spacing:0px;
                font-size:14px;
                font-weight:600;
                color: #ffffff;

            }

            .waves {
                position:relative;
                width: 100%;
                height:15vh;
                margin-bottom:-7px; /*Fix for safari gap*/
                min-height:100px;
                max-height:150px;
            }

            .content {
                position:relative;
                height:100%;
                text-align:center;
                background-color: white;
            }

            /* Animation */

            .parallax > use {
                animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
            }
            .parallax > use:nth-child(1) {
                animation-delay: -2s;
                animation-duration: 7s;
            }
            .parallax > use:nth-child(2) {
                animation-delay: -3s;
                animation-duration: 10s;
            }
            .parallax > use:nth-child(3) {
                animation-delay: -4s;
                animation-duration: 13s;
            }
            .parallax > use:nth-child(4) {
                animation-delay: -5s;
                animation-duration: 20s;
            }
            @keyframes move-forever {
                0% {
                    transform: translate3d(-90px,0,0);
                }
                100% { 
                    transform: translate3d(85px,0,0);
                }
            }
    @media (max-width: 768px) {
        .inner-header img{
            width:40%;
            margin-top: 10%;
        }
    }
</style>